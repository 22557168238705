.contact img {
  max-width: 100%;
}

.contact .form__message {
  align-items: flex-start;
}

.contact .icon-success {
  margin-top: 0.2rem;
}

.contact .field {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 750px) {
  .contact .field {
    margin-bottom: 2rem;
  }
}

.contact__button {
  margin-top: 3rem;
}

@media screen and (min-width: 750px) {
  .contact__button {
    margin-top: 4rem;
  }
}

@media screen and (min-width: 750px) {
  .contact__fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
  }
}
